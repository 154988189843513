/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from 'react';
import Button from '../../bootstrap/Button';
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalFooter } from '../../bootstrap/Modal';
import showNotification from '../../extras/showNotification';

const ViewPdf = ({
  fileUrl,
  open,
  setIsOpen,
  pixHash,
}) => {
  const ref = React.createRef();

  useEffect(()=>{

  },[])

  // const handlerDownload=async (fileUrl, filename)=>{
  //   axios.get(fileUrl, {
  //     responseType: 'blob',
  //     headers:{
  //       'Access-Control-Allow-Origin' : '*',
  //       'Access-Control-Allow-Credentials': 'true'
  //     }
  //   })
  //   .then((res) => {
  //     fileDownload(res.data, filename)
  //   })
  // }

  return (
    <Modal
      id={'modal-register-pix'}
      titleId={'Codigo QR PIX para pagamento'}
      isOpen={open}
      setIsOpen={setIsOpen}
      isStaticBackdrop={true}
      isScrollable={false}
      isCentered={true}
      size="xl"
      isAnimation={true}
    >
      <ModalHeader setIsOpen={setIsOpen}>
        <ModalTitle> 
          <h3> PDF BOLETO PIX IMEDIATO/COBRANÇA </h3>  
        </ModalTitle>
      </ModalHeader>
      <ModalBody ref={ref} height="300%"  style={{height: 600}}>
        <div style={{height: 600}}>
          <object data={fileUrl} type="application/pdf" width="100%" height="100%" />  
        </div>
      </ModalBody>
       <ModalFooter style={{display:'flex', justifyContent:'space-evenly', flexDirection:'row', marginTop:'2%', }}>
            <div>
              <Button
                type='submit'
                color='success'
                icon="ContentCopy"
                rounded={1}
                hoverShadow="xl"
                shadow="xl"
                size="xl"
                onClick={() => {
                  navigator.clipboard.writeText(pixHash.toString);
                  showNotification('success', 'Código copiado para a área de transferência!');
                }}
                >
                COPIAR CÓDIGO  QR
              </Button>
            </div>

            <div>
              <Button
                type='submit'
                color='success'
                icon="Share"
                rounded={1}
                hoverShadow="xl"
                shadow="xl"
                size="xl"
                onClick={() => {
                  window.open(`https://api.whatsapp.com/send?text=Segue o link do Pdf do seu documento: ${fileUrl}`, '_blank');
                }}
                >
                COMPARTILHAR
              </Button>
            </div>
       </ModalFooter>
    </Modal>
  )
}

export default ViewPdf;